import { Component, Input } from '@angular/core';
import { staticPix } from 'pix-qrcode';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-qr-code-pix',
  templateUrl: './qr-code-pix.component.html',
  styleUrls: ['./qr-code-pix.component.scss'],
})
export class QrCodePixComponent {
  public qrCodeImage = '../../assets/images/BBQrCode.jpg';

  @Input() public address!: string;
  @Input() public amount: string = '';

  ngOnChanges(): void {
    if (this.address) {
      this.generateQrCode(this.address, this.amount).then(
        (x) => (this.qrCodeImage = x),
      );
    }
  }

  private generateQrCode = async (
    address: string,
    amount: string,
  ): Promise<string> => {
    const pixQrCode = await staticPix({
      pixKey: address,
      description: 'DLM Deposit',
      merchant: 'DLM Technologia',
      merchantCity: 'Sao Paulo',
      transactionId: uuidv4().replace(/-/gi, '').slice(-20),
      amount: amount || undefined,
    });

    return pixQrCode.qrcode;
  };
}
